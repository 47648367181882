<template>
  <div>

      <div class="mb-3">
        <h4 class="pb-1">{{trans('auth-password-changed',279)}}</h4>
          
        <div class="mb-2 custom-font-size"> {{trans('auth-password-changed-info',279)}} </div>
        
      </div>

      <div class="text-center">
            <app-button type="primary" class="my-4" :block="true" size="md" @click="$router.push({ name: 'Login'})">{{trans('back-to-login',285)}}</app-button>
      </div>
  </div>
</template>
<script>



  export default {

    data() {
      return {
        loading: false,
      };
    },
   
    };
</script>

<style scoped>
.vertical-center {
  min-height: calc(100% -64px); 
  min-height: calc(100vh - 64px); 


  display: flex;
  align-items: center;
}

.app-local-image-logo{
  margin-left: -20px;
}

.custom-font-size{
  font-size: 14px;
}
</style>
