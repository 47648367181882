<template>
  <div class="vertical-center justify-content-center">
    <b-container class="pb-5 app-custom-width">
        <b-row class="justify-content-center">
            <b-col lg="5" md="7">
                <b-overlay :show="loading" :opacity="0.6"> 
                <b-card no-body class="bg-white border-0 mb-0">
                    <b-card-body class="px-lg-4 py-lg-4">
                         <div class="mb-2">
                            
                                <img src="@/assets/images/logo/Logo_nieuw_RGB.svg" class="app-local-image-logo" right height="130px" alt="Happy One" /> 
                                <reset-password-step-1-component v-if="step == 1" :step.sync="step" :token="token"  />  
                                <reset-password-step-2-component v-else />               
                               
                        </div>
                    </b-card-body>
                </b-card>
                </b-overlay>  
            </b-col>
        </b-row>
    </b-container>
  </div>
</template>


<script>
import ResetPasswordStep1Component from './ResetPasswordStep1Component.vue';
import ResetPasswordStep2Component from './ResetPasswordStep2Component.vue';
import axios from "@axios";

  export default {
    
    components: {
        ResetPasswordStep1Component,
        ResetPasswordStep2Component
    },

    mounted() {
        this.resetPassword();
    },

    data() {
      return {
        token: this.$route.params.token,
        step: 1,
        loading: true
      };
    },
    methods: {
     resetPassword() {
       
              axios.post("authorization/validateToken", {
                    token: this.token
                })
              .then( (response) => {
                  if(response.data.error == 'token_invalid'){
                      this.$router.push({ name: 'Login'});
                  } 
              })
              .catch((error) => {
                      console.error(`error during request: ${error}`);
                  
              }).finally(() => {
                        this.loading = false;
                    }); 
        }

    }
  };
</script>

<style scoped>
.vertical-center {
  min-height: calc(100% -64px); 
  min-height: calc(100vh - 64px); 


  display: flex;
  align-items: center;
}

.app-custom-width{
  max-width: 1200px !important;
}

.app-local-image-logo{
  margin-left: -20px;
}
</style>
